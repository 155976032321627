@font-face {
	font-family: 'MiloSlabADAC';
	src: url('https://assets.adac.de/raw/upload/fonts/MiloSlabADAC.woff2') format('woff2'),
		url('https://assets.adac.de/raw/upload/fonts/MiloSlabADAC.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'MiloADAC';
	src: url('https://assets.adac.de/raw/upload/fonts/MiloADAC.woff2') format('woff2'),
		url('https://assets.adac.de/raw/upload/fonts/MiloADAC.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'MiloADAC';
	src: url('https://assets.adac.de/raw/upload/fonts/MiloADAC-italic.woff2') format('woff2'),
		url('https://assets.adac.de/raw/upload/fonts/MiloADAC-italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-display: fallback;
}

@font-face {
	font-family: 'MiloADAC';
	src: url('https://assets.adac.de/raw/upload/fonts/MiloADAC-medium.woff2') format('woff2'),
		url('https://assets.adac.de/raw/upload/fonts/MiloADAC-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'MiloADAC';
	src: url('https://assets.adac.de/raw/upload/fonts/MiloADAC-bold.woff2') format('woff2'),
		url('https://assets.adac.de/raw/upload/fonts/MiloADAC-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: fallback;
}
