* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: smooth;
    scroll-padding-top: 100px;
  }

  body {
    background: rgb(241, 243, 244);
    font-family: MiloADAC, sans-serif;
    font-weight: normal;
    font-feature-settings: "lnum";
  }